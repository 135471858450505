<template>
    <!-- 安全登录 -->
    <div class="loginBg">
        <div id="main">
            <div class="container">
                <transition name="el-fade-in-linear">
                <div class="title fadeInDown">{{$t('login.safeLogin')}}</div>
                </transition>
                <div class="form_box fadeIn">
                    <div class="inp_label">{{$t('common.emailAddress')}}</div>
                    <input v-model="safe_email" class="inp disable" type="text" data-index="username" disabled :placeholder="$t('common.email')"/>

                    <div class="inp_label">
                        {{$t('common.verification')}}
                    </div>
                    <div class="inp_btn">
                        <input v-model="sms_code" class="inp" type="text" data-index="sms_code" :placeholder="$t('common.inputVerification')" />
                        
                        <div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
                        <div v-else class="g_btn btn" @click="handleSendCode">
                            <template v-if="firstSend">{{$t('common.sendVerification')}}</template>
                            <template v-else>{{$t('common.resend')}}</template>
                        </div>
                    </div>

                    <div style="height: 1px;"></div>

                    <button @click="safeLogin" class="btn login">{{$t('common.confirm2')}}</button>
                </div>
                <div class="login_footer fadeInUp">
                    <p><span>System version {{version}} </span> @ 2022 LLKKZ.NET ALL RIGHTS RESERVED</p>
                </div>
            </div>
            <div class="icon">
                <img src="@/assets/images/official/login/icon.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
var timer;
import {Message} from 'element-ui'
import { mapGetters } from 'vuex';
import { WOW } from 'wowjs'
// api
import {sendEmail} from '@/api/user'
export default {
    data(){
        return{
            sms_code:'',
            codeSending: false,
            timeCount: 60,
            firstSend: true,
        }
    },
    computed:{
        ...mapGetters(['safe_account','safe_email','version'])
    },
    mounted(){
        this.$nextTick(()=>{
            this.wow();
        })
    },
    methods:{
        wow(){
            // if( document.querySelectorAll('.wow').length ){
            //     if (! (/msie [6|7|8|9]/i.test(navigator.userAgent))) {
            var wow = new WOW({
                boxClass:     'wow',      // animated element css class (default is wow)
                animateClass: 'animated', // animation css class (default is animated)
                offset:       0,          // distance to the element when triggering the animation (default is 0)
                mobile:       true,       // trigger animations on mobile devices (default is true)
                live:         true       // act on asynchronously loaded content (default is true)
            });
            this.$nextTick(() => {
                wow.init();
            });
            //     };
            // };
        },
        handleSendCode() {
            if(!this.codeSending){
                // 发送验证码
                this.timeCount = 60;
                this.codeSending = true;
                let that = this;
                this.firstSend = false;
                clearInterval(timer);
                timer = setInterval(() => {
                    if(this.timeCount >= 1) {
                        that.timeCount -= 1;
                    } else {
                        clearInterval(timer);
                        this.codeSending = false;
                    }
                }, 1000);
                // 发送验证码
                sendEmail({
                    account:this.safe_account,
                    email:this.safe_email||'',
                    type:0,
                })
            }
        },
        // 安全登录
        safeLogin(){
            if(!this.sms_code){
                Message({
                    message:this.$t('tip.codeEmpty')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else{
                this.$store.dispatch('user/safeLogin',this.sms_code).then(res=>{
                    if(this.$route.query.redirect){
                        this.$router.push({
                            path:this.$route.query.redirect
                        })
                    }else{
                        this.$router.push({
                            path:'/stocks'
                        })
                        // 初始化安全登录的账户、邮箱
                        this.$store.dispatch('user/setSafeAccount','')
                        this.$store.dispatch('user/setSafeEmail','')
                    }
                })
            }
        },
    },
    beforeDestroy() {
        if(timer){
            clearInterval(timer)
            timer=''
        }
    }
}
</script>
<style scoped="scoped" lang="stylus">
	@import '~@/views/login/login.styl';
	@import '~@/assets/style/frames.styl';
</style>